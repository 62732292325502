<!--业务员提成明细-->
<template>
  <div class="salespersonCommission bgblue">
    <!-- 费用来源搜索部分 -->
    <div class="input">
        <el-row  >
        <el-form  class="demo-form-inline" label-width="100px" >
           <el-col :span="4" >
            <el-form-item label="费用来源："  >
              <el-select
                placeholder="全部"
                clearable
              >
                <el-option label="全部" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
       
        </el-form>
      </el-row>
    </div>
    <!-- 图片列表部分 -->
    <div class="pic">
        <el-row :gutter="10">
            <el-col :span="6">
              <div class="grid-content flex justify-between shadow picture">
               <div class="flex1" >
                <img src="../../assets/images/qianbao.png" alt="">
               </div>
            
                <div class="flex3">
                    <p class="text">6,141,672.00</p>
                    <p class="money">总金额(元)</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content flex justify-between shadow picture">
                    <div class="flex1">
                <img src="../../assets/images/qianbao.png" alt="">
               </div>
               <div class="flex3">
                <p class="text">0.00</p>
                <p class="money">待确认金额(元)</p>
            </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content flex justify-between shadow picture">
                    <div class="flex1">
                <img src="../../assets/images/qianbao.png" alt="">
               </div>
               <div class="flex3">
                <p  class="text">-14,470.00</p>
                <p   class="money">已确认金额(元)</p>
            </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content flex justify-between shadow picture">
                    <div class="flex1">
                <img src="../../assets/images/qianbao.png" alt="">
               </div>
               <div class="flex3">
                <p class="text">0.00</p>
                <p  class="money">待开票金额(元)</p>
            </div>
                </div>
            </el-col>
        </el-row>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style  lang="less">
//费用总览模块
//费用来源
.installationFeeDetail{
    padding: 10px;
   .input{
   position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding-top: 10px;
    box-shadow: 0px 0px 10px #ccc;
   }
   //图片部分
   .pic{
    margin-top: 10px;
    box-sizing: border-box;
    .text{
        font-weight: 800;
    }
    .money{
        font-size: 12px;
        color: #adadad;
    }
    img{
       
     margin: 15px;
    }
    .picture{
        background-color: #fff;
        border-radius: 10px;
        
    }
   }

}

</style>